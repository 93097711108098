<template>
    <div class="past-activities">
        <h6 class="section-title">
            PAST ACTIVITIES
        </h6>
        <div class="past-activities-container">
            <vue-tabs>
                <v-tab title="All">
                    <template v-for="activity in pastActivities.all">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>
                <v-tab title="Call" icon="fas fa-phone">
                    <template v-for="activity in pastActivities.call">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>

                <v-tab title="Text" icon="fas fa-sms">
                    <template v-for="activity in pastActivities.text">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>

                <v-tab title="Email" icon="fas fa-envelope">
                    <template v-for="activity in pastActivities.email">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>

                <v-tab title="Chat" icon="fas fa-headset">
                    <template v-for="activity in pastActivities.chat">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>

                <v-tab title="Notes" icon="fas fa-clipboard">
                    <template v-for="activity in pastActivities.notes">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>

                <v-tab title="Activity" icon="fas fa-calendar-day">
                    <template v-for="activity in pastActivities.activity">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>

                <v-tab title="Files" icon="fas fa-cloud-upload-alt">
                    <template v-for="activity in pastActivities.files">
                        <timeline-activity :key="Number(activity.id)" :activity="activity" />
                    </template>
                </v-tab>
            </vue-tabs>
        </div>
    </div>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";

export default {
    name: "PastActivities",
    components: {
        VueTabs,
        VTab,
        TimelineActivity: () => import(/* webpackChunkName: "timeline-activity" */ "../timeline-activity/")
    },
    props: {
        pastActivities: {
            type: Object,
            required: true
        }
    }
}
</script>
<style lang="scss">
.past-activities {
    margin-bottom: 40px;

    .section-title {
        font-family: 'Lato', sans-serif;
        color: #9594AF;
        font-size: 16px;
        letter-spacing: 3px;
        margin-bottom: 25px;
    }

    .vue-tabs {
        .nav-tabs-navigation {
            .nav-tabs-wrapper {
                .nav.nav-tabs {
                    border-bottom: 0;
                    justify-content: flex-start;

                    li {
                        a {
                            color: #B3C1CB;
                            padding: 5px 35px;
                        }

                        &.active {
                            .active_tab {
                                border-bottom: 3px solid var(--secondary-color);
                            }
                        }
                    }
                }
            }
        }
    }

    .past-activities-container {
        .tab-content {
            padding: 20px 0;

            .timeline-activity {
                .activity-icon-container {
                    &::before, &::after {
                        border-style: solid;
                    }
                }

                &:first-child {
                    .activity-icon-container {
                        &::before {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    .activity-icon-container {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
</style>

